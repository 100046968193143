<template>
  <div class="search">
    <header class="main-header">
      <div class="row row-page-top">
        <h1 class="poster-rail--title section-title">
          Resultados para "{{ filterText }}"
        </h1>
      </div>
    </header>

    <template v-if="!showLoader">
      <main
        v-if="isEmpty"
        class="wrapper container-fluid my-content empty"
        role="main"
      >
        <section class="empty-state">
          <figure>
            <img
              src="../assets/images/empty-illustration.svg"
              width="350"
              height="211"
              alt="Empty Illustration"
            />
          </figure>

          <h1 class="h1">No se encontraron resultados para tu búsqueda.</h1>
        </section>
      </main>

      <main
        v-if="!isEmpty"
        class="wrapper container-fluid catch-up"
        role="main"
      >
        <template v-if="resultados.channels.length > 0">
          <h1 class="poster-rail--title rail--title-channels">
            <!-- <i class="zmdi zmdi-tv-list"></i> -->
            <span class="text">Canales</span>
          </h1>

          <div
            class="row featured-content channel-rail"
          >
            <Slider
              :data="resultados.channels"
              :icon="''"
              :channelselector="true"
            >
              <template slot-scope="itemslider">
                <div
                  v-for="(item, index) of itemslider.data"
                  :key="item.id"
                  class="slider-item channel-item"
                >
                  <a>
                    <figure v-on:click="playChannel(item.id)">
                      <img
                        :src="item.logo"
                        :alt="item.name"
                        class="channel-logo"
                      />
                      <figcaption>{{ item.name }}</figcaption>
                    </figure>
                  </a>
                </div>
              </template>
            </Slider>
          </div>
        </template>

        <template v-if="resultados.live.length > 0">
          <Slider :data="resultados.live" :title="'En vivo'">
            <template slot-scope="itemslider">
              <CardTV
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset + '-' + index"
                :data="item"
                :search="true"
                componentOrigin="SCH"
                :sliderId="'busquedas-en-vivo'"
                :sliderTitle="'En vivo'"
                :cardId="index"
              />
            </template>
          </Slider>
        </template>

        <template v-if="resultados.epg.length > 0">
          <Slider :data="resultados.epg" :title="'Guía'">
            <template slot-scope="itemslider">
              <CardTV
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset + '-' + index"
                :data="item"
                :search="true"
                componentOrigin="SCH"
                :sliderId="'busquedas-guia'"
                :sliderTitle="'Guía'"
                :cardId="index"
              />
            </template>
          </Slider>
        </template>

        <template v-if="resultados.catchup.length > 0">
          <Slider
            :data="resultados.catchup"
            :title="'Programación últimas 24 horas'"
          >
            <template slot-scope="itemslider">
              <CardTV
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset"
                :data="item"
                :search="true"
                componentOrigin="SCH"
                :sliderId="'busquedas-ult-24hs'"
                :sliderTitle="'Programación últimas 24 horas'"
                :cardId="index"
              />
            </template>
          </Slider>
        </template>

        <template v-if="resultados.movies.length > 0">
          <Slider :data="resultados.movies" :title="'Películas'">
            <template slot-scope="itemslider">
              <CardVOD
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset"
                :data="item"
                componentOrigin="SCH"
                :sliderId="'busquedas-peliculas'"
                :sliderTitle="'Películas'"
                :cardId="'card-' + index"
              />
            </template>
          </Slider>
        </template>

        <template v-if="resultados.series.length > 0">
          <Slider :data="resultados.series" :title="'Series'">
            <template slot-scope="itemslider">
              <CardVOD
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset"
                :data="item"
                componentOrigin="SCH"
                :sliderId="'busquedas-series'"
                :sliderTitle="'Series'"
                :cardId="'card-' + index"
              />
            </template>
          </Slider>
        </template>
      </main>
    </template>

    <Loader v-if="showLoader" />
  </div>
</template>

<script>

import { blockAsset } from "../helpers/parentalControl";

export default {
  name: "Search",

  components: {
    CardTV: () => import("@/components/Card-TV.vue"),
    CardVOD: () => import("@/components/Card-VOD.vue"),
    Loader: () => import("@/components/Loader.vue"),
    Slider: () => import("@/components/Slider.vue"),
  },

  data() {
    return {
      resultados: {
        channels: [],
        epg: [],
        live: [],
        catchup: [],
        movies: [],
        series: [],
      },
      showLoader: true,
      isEmpty: false,
    };
  },

  mounted() {
    this.onSearch();
  },

  computed: {
    filterText() {
      return this.$route.params.str;
    },
  },

  methods: {
    onSearch() {
      let self = this;

      self.showLoader = true;

      telecentro.tplay.core.biz.smartSearch.smartSearch(
        self.filterText,
        function (ret) {
          self.resultados.channels = ret.channels;
          self.resultados.epg = ret.epg;
          self.resultados.live = ret.live;
          self.resultados.catchup = ret.catchup;
          self.resultados.movies = ret.vod.peliculas;
          self.resultados.series = ret.vod.series;

          self.isEmpty = !(
            self.resultados.channels.length ||
            self.resultados.epg.length ||
            self.resultados.live.length ||
            self.resultados.catchup.length ||
            self.resultados.movies.length ||
            self.resultados.series.length
          );

          self.$nextTick(function () {
            self.showLoader = false;
          });
        },
        function (error) {
          self.resultados.channels = [];
          self.resultados.epg = [];
          self.resultados.live = [];
          self.resultados.catchup = [];
          self.resultados.movies = [];
          self.resultados.series = [];

          self.isEmpty = true;

          self.$nextTick(function () {
            self.showLoader = false;
          });

          console.error("Error de búsqueda", error);
        }
      );
    },

    playChannel(channelId) {
      let self = this;
      telecentro.tplay.core.epg.obtenerProgramacionHoy(
        channelId,
        true,
        function (hoy) {
          if (!hoy[0]) {
            //ERROR
            return;
          }

          let dataAsset = hoy[0];

          if (blockAsset(dataAsset)) {
            self.$bus.$emit("modal-pin:show", function () {
              self.$bus.$emit("player:set-source", dataAsset);
              tplay.sendAudience(dataAsset, 'SCH');
            });
          } else {
            self.$bus.$emit("player:set-source", dataAsset);
            tplay.sendAudience(dataAsset, 'SCH');
          }
          let butttonClose = document.querySelector(".close");
          butttonClose?.click();
        }
      );
    },
  },

  watch: {
    $route(to, from) {
      this.onSearch();
    },
  },
};
</script>
